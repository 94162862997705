/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "These are the second version of the themes used for dealers on the Glovebox system. This version changed the CSS framework and how the themes were structured. Incorporating Foundation gave us the responsiveness we wanted, and a sass hierarchy I built gave us the ease of extending themes."), "\n", React.createElement(_components.p, null, "These were the first responsive dealer sites on any system in Canada, and it was no easy task. The hardest part of this build was the navigation. I wanted to use the built-in Foundation navigation, but the HTML structure was very limiting, especially when creating the drop-downs on the full-sized site. With a lot of perseverance, patience and a little magic, I found a solid solution, and now it functions as was intended."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
